<script lang="ts" setup>
import { useGetTwicUrlBackground } from '~/composables/useImage'

defineProps<{
  content?: object
}>()

onMounted(() => {
  const contents = document.querySelectorAll('.offers .offer-content')
  let maxHeight = 0
  contents.forEach((item) => {
    if (item.offsetHeight > maxHeight) maxHeight = item.offsetHeight
  })

  contents.forEach((item) => {
    item.style.height = `${maxHeight}px`
  })
})
</script>

<template>
  <section v-if="content" class="offers block">
    <UContainer>
      <div class="items-center lg:flex">
        <div class="lg:max-w-[280px]">
          <h2 class="text-h3 text-primary lg:text-48 lg:leading-58">
            {{ content.title }}
          </h2>
          <div class="my-24 h-2 w-62 bg-secondary-light"></div>
          <ak-text class="offers__resume text-p1" :text="content.text" />
        </div>
        <div
          class="grid w-full grid-cols-1 gap-20 max-lg:mt-20 md:grid-cols-3 lg:ml-20"
        >
          <div v-for="offer in content.offers" :key="offer.id">
            <component
              :is="offer.link ? 'ak-link' : 'div'"
              class="relative z-10 flex flex-col rounded-10 bg-cover p-20 max-lg:aspect-[16/9] lg:h-[600px] lg:p-40"
              :data-twic-background="useGetTwicUrlBackground(offer.image)"
              :link="offer.link"
            >
              <div
                class="absolute bottom-0 left-0 right-0 top-0 -z-10 rounded-10 bg-black/50"
              ></div>
              <div class="offer-content mt-auto">
                <h3 class="text-h4 text-blue-200">{{ offer.title }}</h3>
                <AkText :text="offer.text" class="offers__text text-p1" />
              </div>
            </component>
          </div>
        </div>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.offers {
  &__resume {
    @apply text-primary text-p1;

    :deep(p) {
      @apply text-primary text-p1;
    }
  }

  &__text {
    @apply mt-16 text-p1 text-blue-200;

    :deep(p) {
      @apply text-p1 text-blue-200;
    }
  }
}
</style>
